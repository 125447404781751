import React from 'react';
import { graphql } from 'gatsby';

import HeroTypeC from '../components/Heroes/HeroTypeC';
import BannerInfoTypeB from '../components/Banners/BannerInfoTypeB';
import FaqSection from '../components/FAQ/FaqSection';
import BannerInfoTypeA from '../components/Banners/BannerInfoTypeA';
import BannerTypeA from '../components/Banners/BannerTypeA';
import TripleBanner from '../components/Location/TripleBanner';
import MultiArticleV2 from '../components/Articles/MultiArticleV2';
import Reviews from '../components/Reviews/Review';
import SEO from '../components/SEO/SEO';

export default function NearMe({ data }) {
  const hero_type_c = data.strapiNearMePage.Hero_Type_C;
  const info_banner_type_b = data.strapiNearMePage.Info_Banner_Type_B;
  const banner_type_middle = data.strapiNearMePage.Blog_Banner_Middle;
  const reviews = data.strapiNearMePage.Reviews_Section;
  const info_banner_type_a = data.strapiNearMePage.Info_Banner_Type_A;
  const triple_banner = data.strapiNearMePage.Triple_Info_Banner_Section;
  const multi_article = data.strapiNearMePage.Multi_Article_Section;
  const faq = data.strapiNearMePage.FAQ;
  const banner_type_bottom = data.strapiNearMePage.Blog_Banner_Bottom;

  return (
    <>
      <SEO
        title="Stop Searching ‘Dispensaries Near Me’ | House of Cannabis"
        description="If you’ve been frustrated trying to find ‘dispensaries 
                    near me’ look no further than House of Cannabis! Now with six convenient locations across Ontario."
      />
      <HeroTypeC
        title={hero_type_c.Title}
        subtitle={hero_type_c.Content}
        heroImage={
          hero_type_c.BackgroundImage.localFile.childImageSharp.gatsbyImageData
        }
      />
      <BannerInfoTypeB
        description={info_banner_type_b.Content}
        bgImage={
          info_banner_type_b.BackgroundImage.localFile.childImageSharp
            .gatsbyImageData
        }
        buttonLink={info_banner_type_b.Button.Custom_Link_Slug}
        center
      />
      <BannerTypeA
        title={banner_type_middle.Article_Link.Title}
        description={banner_type_middle.Article_Link.Preview_Text}
        bannerImage={
          banner_type_middle.Article_Link.BannerImage.localFile.childImageSharp
            .gatsbyImageData
        }
        /*    link={`/blog/${banner_type_middle.Article_Link.slug}`} */
        link="/menu"
        buttonLabel="READ ARTICLE"
      />

      <Reviews title={reviews.Title} reviewsdata={reviews.Single_Review} />

      <BannerInfoTypeA
        title={info_banner_type_a.Title}
        description={info_banner_type_a.Content}
        bgImage={
          info_banner_type_a.Image.localFile.childImageSharp.gatsbyImageData
        }
      />

      <TripleBanner
        title={triple_banner.Title}
        banners={triple_banner.Single_Triple_Info_Banner}
      />

      <MultiArticleV2
        leftarticle={multi_article.Left_Mutli_Article}
        rightarticle={multi_article.Right_Multi_Article}
      />

      <FaqSection faqdata={faq.FAQs} title={faq.Title} />

      <BannerTypeA
        title={banner_type_bottom.Title}
        description={banner_type_bottom.Content}
        bannerImage={
          banner_type_bottom.Background_Image.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/blog"
        buttonLabel="SEE ALL BLOGS"
      />
    </>
  );
}

export const query = graphql`
  query {
    strapiNearMePage {
      Hero_Type_C {
        Title
        Content
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 85)
            }
          }
        }
      }

      Info_Banner_Type_B {
        Button {
          Custom_Link_Slug
        }
        Content
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }

      Blog_Banner_Middle {
        Article_Link {
          Preview_Text
          Title
          slug
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }

      Reviews_Section {
        Title
        Single_Review {
          By
          Date
          Number_Of_Stars
          Quote
        }
      }

      Info_Banner_Type_A {
        Title
        Content
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }

      Triple_Info_Banner_Section {
        Single_Triple_Info_Banner {
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 85)
              }
            }
          }
          Content
          Header
        }
        Title
      }

      Multi_Article_Section {
        Left_Mutli_Article {
          article {
            Title
            slug
            BannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        Right_Multi_Article {
          article {
            Title
            slug
          }
        }
      }

      FAQ {
        FAQs {
          Answer
          Question
        }
        Title
      }

      Blog_Banner_Bottom {
        Blog_Button_Link {
          Link_Slug
        }
        Content
        Title
        Background_Image {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
      }
    }
  }
`;
